import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { BgImage } from "gbimage-bridge";
import React, { useEffect, useRef, useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import CartContext from "../context/CartContext";
import { Helmet } from "react-helmet";
import SafeHtmlParser from "../components/safeHtmlParser";

const CalculatorPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			allWpCalculatorLocation {
				nodes {
					slug
					title
					calculatorLocationFields {
						londonLocation
					}
				}
			}
			pageData: wpPage(slug: { eq: "office-calculator" }) {
				id
				title
				calcpageFields {
					heading
					ctaButton {
						title
						url
					}
					contentSectionCards {
						cardTitle
						cardDescription
						cardIcon {
							sourceUrl
						}
					}
					backgroundImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				seoFields {
					metaTitle
					metaDescription
					opengraphDescription
					opengraphTitle
					productSchema
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
							publicURL
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, calcpageFields },
		pageData,
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const [slug, setSlug] = useState("/calculator/bank-london");
	const [numberOfDesks, setNumberOfDesks] = useState(1);

	const handleLocationChange = (event) => {
		setSlug(`/calculator/${event.target.value}`);
	};

	const handleNumberOfDesksChange = (event) => {
		const value = event.target.valueAsNumber;
		if (value >= 1) {
			setNumberOfDesks(value);
		}
	};

	const { settingVal, numberOfDesksCalculator } = useContext(CartContext);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Office Calculator",
				item: {
					url: `${siteUrl}/calculator`,
					id: `${siteUrl}/calculator}`,
				},
			},
		],
	};

	useEffect(() => {
		settingVal(numberOfDesks, "numberOfDesksCalculator");
	}, [numberOfDesks]);

	// Separate and sort London and non-London locations
	const londonLocations = data.allWpCalculatorLocation.nodes
		.filter((node) => node.calculatorLocationFields.londonLocation)
		.sort((a, b) => a.title.localeCompare(b.title));

	const nonLondonLocations = data.allWpCalculatorLocation.nodes
		.filter((node) => !node.calculatorLocationFields.londonLocation)
		.sort((a, b) => a.title.localeCompare(b.title));

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/calculator`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.localFile.publicURL}`,
							width: `${seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<div className="w-100 position-relative">
							<BgImage
								style={{ zIndex: 0 }}
								className="w-100 h-100 position-absolute start-0 top-0"
								image={
									calcpageFields.backgroundImage?.localFile.childImageSharp
										.gatsbyImageData
								}
							/>
							<div
								style={{ background: "#0000005A", zIndex: 1 }}
								className="position-absolute w-100 h-100 start-0 top-0"
							></div>
							<Container
								style={{ zIndex: 2 }}
								className="position-relative py-5 py-lg-7"
							>
								<Row className="justify-content-center">
									<Col className="text-center" lg={8} xl={7}>
										<div className="normal-font pb-5">
											<SafeHtmlParser htmlContent={calcpageFields?.heading} />
										</div>
										<div
											style={{
												boxShadow: "0px 1px 20px #00000029",
												borderRadius: "12px",
												zIndex: 2,
											}}
											className="py-3 mx-auto py-md-4 px-3 px-md-4 w-md-80 w-lg-60 mb-3 bg-white position-relative"
										>
											<h3 className="pb-3">{pageData.title}</h3>
											<Form.Group
												className="custom-select-wrapper mb-3 mb-xl-4"
												controlId="selectLocation"
											>
												<Form.Control
													as="select"
													className={`custom-select p-3 `}
													onChange={handleLocationChange}
												>
													<option value="">Select a location</option>

													{/* Render London locations first */}
													{londonLocations.map((node) => (
														<option key={node.slug} value={node.slug}>
															{node.title}
														</option>
													))}

													{/* Render non-London locations next */}
													{nonLondonLocations.map((node) => (
														<option key={node.slug} value={node.slug}>
															{node.title}
														</option>
													))}
												</Form.Control>
											</Form.Group>
											<Form.Group className={``} controlId="howManyDesks">
												<Form.Control
													name="HowManyDesks"
													placeholder="How many desks do you need?"
													onChange={handleNumberOfDesksChange}
													type="number"
													min={1}
													className={` p-3`}
													style={{ WebkitAppearance: "none" }}
												/>
											</Form.Group>

											<Button
												size="small"
												className="btn btn-darker-blue text-white mt-3 px-6 rounded-pill w-100 "
												type="submit"
												id="contact-send-btn"
												as={Link}
												to={slug}
											>
												SUBMIT
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</section>
					<section className="py-5">
						<Container>
							<Row className="  mt-md-5 mt-4 gx-md-4 gx-lg-5 px-md-4 px-3">
								<>
									{calcpageFields?.contentSectionCards.map((item, i) => (
										<Col
											md={6}
											lg={4}
											className={
												i + 1 === calcpageFields?.contentSectionCards.length
													? "mb-3 mb-md-2 pt-md-5 pt-lg-0"
													: "mb-3 mb-md-2"
											}
											key={i}
										>
											<div className=" ">
												<div className="d-flex  mb-3 align-items-center">
													<img
														src={item?.cardIcon?.sourceUrl}
														className="me-2"
														style={{
															height: "30px",
														}}
													/>
													<h4 className="d-inline-block pb-0 mb-0">
														{item?.cardTitle}
													</h4>
												</div>
												<div>
													<SafeHtmlParser htmlContent={item?.cardDescription} />
												</div>
											</div>
										</Col>
									))}
								</>
							</Row>
							<Row>
								<Col className="text-center">
									<div>
										<Link
											className="w-100 w-md-auto btn fw-bold rounded-pill mt-md-5 mt-4 text-white bg-primary px-4 py-2  mb-4"
											to={calcpageFields?.ctaButton.url}
										>
											{calcpageFields?.ctaButton?.title}
										</Link>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default CalculatorPage;
